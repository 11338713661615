<template>
  <div class="information-detail-container">
    <div class="head">
      <div class="top">
        <span class="type">[资讯]</span>
        <span class="title">{{ detailsData.title }}</span>
      </div>
      <ul class="icon-container">
        <li class="share">
          <img v-lazy="require('@/assets/qietu/icon-01.png')" alt="" />
          <ul class="share_box">
            <div class="sanjiao"></div>
            <li @click.stop="weChat" class="weChat">
              <img src="@/assets/img/details/weChat.png" alt="" />
              <div>微信</div>
              <div
                :id="`WXqrcode${detailsData.id}`"
                :ref="`WXqrcode${detailsData.id}`"
                class="ma"
              ></div>
            </li>
            <li @click.stop="weibo">
              <img src="@/assets/img/details/microblog.png" alt="" />
              <div>新浪微博</div>
            </li>
            <li @click.stop="qq" class="qq">
              <img src="@/assets/img/details/QQ.png" alt="" />
              <div>QQ</div>
              <div
                :id="`QQqrcode${detailsData.id}`"
                :ref="`QQqrcode${detailsData.id}`"
                class="ma"
              ></div>
            </li>
            <li @click.stop="space">
              <img src="@/assets/img/details/qqSpace.png" alt="" />
              <div>QQ空间</div>
            </li>
          </ul>
        </li>
        <li @click.stop="clickFn()">
          <img src="@/assets/svg/svg/icon-08.svg" v-if="isCollect" alt="" />
          <img v-else src="@/assets/svg/svg/icon-02.svg" alt="" />
        </li>
      </ul>
      <p>
        <span>发布时间：</span
        >{{ detailsData.createTime && detailsData.createTime.split(" ")[0] }}
      </p>
      <p>
        <span>来&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;源：</span
        >{{ detailsData.source }}
      </p>
    </div>
    <div class="content" v-html="detailsData.content"></div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import QRCode from "qrcodejs2";
export default {
  data() {
    return {
      detailsData: {},
      WXurl: "",
      QQurl: "",
      isCollect: false,
    };
  },
  computed: {
    ...mapState(["table", "hasLogin", "token"]),
  },
  created() {
    this.getDetails({
      articleType: 5,
      articleId: this.$route.query.id,
      projectId: 3,
    });
    this.qq();
    this.weChat();
    if (this.hasLogin) {
      this.TFCollect({
        action: this.isCollect ? 0 : 1,
        articleId: this.$route.query.id,
        articleType: 5,
      });
    }
    this.getRead();
  },
  methods: {
    async getRead() {
      await this.$apis.autherServe.readCount({
        articleId: this.$route.query.id,
        articleType: 5,
      });
    },
    async getDetails(data) {
      const resp = await this.$apis.detailServe.getDetails(data);
      this.detailsData = resp.data.data;
      document.title = this.detailsData.title + "-百家在线";
    },
    clickFn() {
      this.collect(this.detailsData);
    },
    // 收藏
    async collect() {
      if (!this.hasLogin) {
        this.$store.commit("showAlert", true);
        return;
      }
      let obj = {
        action: this.isCollect ? 0 : 1,
        articleId: this.$route.query.id,
        articleType: 5,
      };
      await this.$apis.searchServe.CollectItem(obj);
      await this.TFCollect();
    },
    async TFCollect() {
      let obj = {
        articleId: this.$route.query.id,
        articleType: 5,
      };
      let res = await this.$apis.searchServe.isCollect(obj);
      this.isCollect = res.isCollect;
    },
    getUrl() {
      let url =
        this.$global.url3 +
        `/informationdetail?articleType=5&id=${this.$route.query.id}`;
      return url;
    },
    weChat() {
      if (this.WXurl !== "") return;
      this.WXurl = this.getUrl();
      // 使用$nextTick确保数据渲染
      this.$nextTick(() => {
        this.WXcrateQrcode();
      });
    },
    WXcrateQrcode() {
      this.qr = new QRCode("WXqrcode" + this.detailsData.id, {
        width: 150,
        height: 150, // 高度
        text: this.WXurl, // 二维码内容
      });
    },
    QQcrateQrcode() {
      this.qr = new QRCode("QQqrcode" + this.detailsData.id, {
        width: 150,
        height: 150, // 高度
        text: this.QQurl, // 二维码内容
      });
    },
    qq() {
      if (this.QQurl !== "") return;
      this.QQurl = this.getUrl();
      // 使用$nextTick确保数据渲染
      this.$nextTick(() => {
        this.QQcrateQrcode();
      });
    },
    weibo() {
      let url = encodeURIComponent(window.location.href);
      let srcUrl = "";
      this.detailsData.cover
        ? (srcUrl = `pic=${this.$global.imgUrl + this.detailsData.cover}`)
        : (srcUrl = "");
      window.open(
        `https://service.weibo.com/share/share.php?${srcUrl}url=${url}&&title=${this.detailsData.title}`
      );
    },
    space() {
      let url = encodeURIComponent(window.location.href);
      let srcUrl = "";
      this.detailsData.cover
        ? (srcUrl = `pics=${this.$global.imgUrl + this.detailsData.cover}`)
        : (srcUrl = "");
      window.open(
        `https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?${srcUrl}url=${url}&&title=${this.detailsData.title}`
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.information-detail-container {
  .icon-container {
    margin-top: 16px;
    display: flex;
    li {
      width: 63px;
      height: 24px;
      margin-right: 13px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .share:hover {
      .share_box {
        display: block;
      }
    }
    .share {
      position: relative;
      .share_box {
        cursor: pointer;
        display: none;
        right: -133px;
        top: -130px;
        position: absolute;
        width: 133px;
        height: 167px;
        background: #ffffff;
        box-shadow: 3px 1px 7px 0px rgba(8, 8, 64, 0.23);
        border-radius: 10px;
        .weChat:hover {
          .ma {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        .weChat {
          position: relative;
          .ma {
            display: none;
            left: 123px !important;
            position: absolute !important;
            width: 200px !important;
            top: -10px !important;
            height: 167px !important;
            background: white !important;
            box-shadow: 3px 1px 7px 0px rgba(8, 8, 64, 0.23);
            border-radius: 10px;
          }
        }
        .qq:hover {
          .ma {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .qq {
          position: relative;
          .ma {
            display: none;
            left: 123px !important;
            position: absolute !important;
            width: 200px !important;
            top: -90px !important;
            height: 167px !important;
            background: white !important;
            box-shadow: 3px 1px 7px 0px rgba(8, 8, 64, 0.23);
            border-radius: 10px;
          }
        }
        li {
          margin-top: 10px;
          margin-bottom: 0px;
          display: flex;
          flex-wrap: nowrap;
          //background: pink;
          justify-content: center;
          align-items: center;
          width: 133px;
          height: 30px;

          div {
            width: 60px;
            //background: yellow;
            margin-left: 10px;
          }
          img {
            width: 20px;
            height: 20px;
          }
        }
        .sanjiao {
          width: 0;
          height: 0;
          border-top: 3px solid transparent;
          border-right: 6px solid #fff;
          position: absolute;
          top: 50%;
          margin-top: -3px;
          left: -6px;
          border-bottom: 3px solid transparent;

          //background: pink;
        }
      }
    }
  }
  .head {
    border-bottom: 1px dashed #d6d6d6;
    .top {
      display: flex;
      align-items: center;
      .type {
        font-size: 30px;
        color: #999999;
      }
      .title {
        font-size: 30px;
        color: #333333;
        margin: 0 5px;
      }
      .column {
        display: inline-block;
        width: 76px;
        height: 30px;
        background: #00a4ff;
        border: 1px solid #00a4ff;
        border-radius: 11px;
        font-size: 14px;
        color: #ffffff;
        text-align: center;
        line-height: 30px;
      }
    }
    p {
      margin: 15px 0;
      span {
        font-size: 14px;
        color: #999999;
        margin-top: 14px;
      }
    }
    .auther {
      font-size: 14px;
      color: #999999;
      margin-top: 14px;
      span {
        font-size: 14px;
        color: #333333;
      }
    }
    .unit {
      margin-top: 14px;
      font-size: 14px;
      color: #999999;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      span {
        font-size: 14px;
        color: #333333;
      }
    }
  }
  .content {
    padding-top: 40px;
    ::v-deep table {
      border-collapse:collapse;
      border-spacing:0;
      th {
        width: 190px;
        height: 35px;
        min-height: 30px;
        border: 1px solid #ccc;
        padding: 3px 5px;
        background-color: #f1f1f1;
      }
      td {
        width: 190px;
        height: 35px;
        min-height: 30px;
        border: 1px solid #ccc;
        padding: 3px 5px;
      }
    }
  }
}
</style>
